import React, { Component } from 'react';
import { connect } from 'react-redux'
import ContentLoader from 'react-content-loader'

class Skelton extends Component {
    constructor(props: any) {
        super(props);
    }
    render() {

        return (
            <div style={{ padding: "0px" }} >
                <ContentLoader height={15} style={{ width: "100%" }}>
                    <rect x="0" y="0" rx="4" ry="4" width="100%" height="200" />
                    {/* <rect x="15" y="50" rx="2" ry="2" width="100%" height="150" /> */}
                    {/* <rect x="15" y="230" rx="2" ry="2" width="170" height="20" />
                    <rect x="60" y="230" rx="2" ry="2" width="170" height="20" /> */}
                </ContentLoader>
            </div>
        );
    }
};
export default connect(null, null)(Skelton);
